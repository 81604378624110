import HeaderSocial from './socialHeader';
import Me from '../../assets/me.jpg';
import React from 'react';
import Cta from './resumeTalk';
import './header.css';

function Header() {
    return (
        <header id='home'>
            <div className='container header_container'>
                <section>
                    <h5>Hello I'm</h5>
                    <h1>KISHAN MODI</h1>
                    <h5 className='text-light'>
                        Software Developer / Data Enthusiast
                    </h5>
                </section>

                <Cta />
                <HeaderSocial />

                <div className='me'>
                    <img
                        src={Me}
                        alt='me'
                    />
                </div>

                <a
                    href='#contact'
                    className='scroll_down'>
                    Scroll Down
                </a>
            </div>
        </header>
    );
}

export default Header;
