import Img1 from '../../assets/project_images/Nourish.jpg';
import Img2 from '../../assets/project_images/Job.jpg';
import Img3 from '../../assets/project_images/Movies.jpg';
import Img4 from '../../assets/project_images/URL.jpg';
import Img5 from '../../assets/project_images/Torrent.jpg';
import Img6 from '../../assets/project_images/FileFuse.jpg';
import React from 'react';
import './portfolio.css';

const data = [
    {
        id: 1,
        image: Img1,
        title: 'Nourish Net',
        github: 'https://github.com/kishanmodi/Nourish-Net',
        demo: 'https://nourish-net.streamlit.app/'
    },
    {
        id: 6,
        image: Img6,
        title: 'FileFuse',
        github: 'https://github.com/kishanmodi/FileFuse',
        demo: ''
    },
    {
        id: 2,
        image: Img2,
        title: 'Job Radar',
        github: 'https://github.com/kishanmodi/job-radar',
        demo: ''
    },
    {
        id: 3,
        image: Img3,
        title: 'MoviesToWatch',
        github: 'https://github.com/kishanmodi/MoviesToWatch-react',
        demo: 'https://movies-to-watch.vercel.app/'
    },
    {
        id: 4,
        image: Img4,
        title: 'URL Shortener',
        github: 'https://github.com/kishanmodi/url-shortner',
        demo: 'https://urlxd.glitch.me/'
    },
    {
        id: 5,
        image: Img5,
        title: 'Torrent Scrapper',
        github: 'https://github.com/kishanmodi/Torrent-Scrapper-React',
        demo: 'https://magnet-s.vercel.app/'
    }
];

const Portfolio = () => {
    return (
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className='container portfolio_container'>
                {data.map(({ id, image, title, github, demo }) => {
                    return (
                        <article
                            className='portfolio_item'
                            key={id}>
                            <div className='portfolio_item-image'>
                                <img
                                    src={image}
                                    alt={title}
                                />
                            </div>
                            <h3>{title}</h3>
                            <div className='portfolio_item-cta'>
                                <a
                                    href={github}
                                    className='btn'>
                                    Github
                                </a>

                                {demo && (
                                    <a
                                        href={demo}
                                        className='btn btn-primary'
                                        target='_blank'
                                        rel='noreferrer'>
                                        Live Demo
                                    </a>
                                )}
                            </div>
                        </article>
                    );
                })}
            </div>
        </section>
    );
};

export default Portfolio;
