import { BsLinkedin } from 'react-icons/bs';
import { BsGithub } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import React from 'react';

function headerSocial() {
    return (
        <div className='header_social'>
            <a
                href='https://www.linkedin.com/in/kishan-modi/'
                target='https://www.linkedin.com/in/kishan-modi/'
                rel='noreferrer'>
                <BsLinkedin />
            </a>
            <a
                href='https://www.instagram.com/kishanmodi_/'
                target='https://www.instagram.com/kishanmodi_/'
                rel='noreferrer'>
                <BsInstagram />
            </a>
            <a
                href='https://www.github.com/kishanmodi/'
                target='https://www.github.com/kishanmodi/'
                rel='noreferrer'>
                <BsGithub />
            </a>
        </div>
    );
}

export default headerSocial;
