import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { FaFigma, FaInstagram } from 'react-icons/fa';
import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <a
                href='/#'
                className='footer_logo'>
                Kishan Modi
            </a>

            <ul className='permalinks'>
                <li>
                    <a href='/#'>Home</a>
                </li>
                <li>
                    <a href='#about'>About</a>
                </li>
                <li>
                    <a href='#experience'>Experience</a>
                </li>
                <li>
                    <a href='#portfolio'>Portfolio</a>
                </li>
                <li>
                    <a href='#contact'>Contact</a>
                </li>
            </ul>

            <div className='footer_socials'>
                <a
                    target='_blank'
                    href='https://linkedin.com/in/kishan-modi'>
                    <BsLinkedin />
                </a>
                <a
                    target='_blank'
                    href='https://www.github.com/kishanmodi'>
                    <BsGithub />
                </a>
                <a
                    target='_blank'
                    href='https://instagram.com/kishanmodi_'>
                    <FaInstagram />
                </a>
            </div>
            <div className='footer_copyright'>
                <small>Made with ❤️ by Kishan</small>
            </div>
        </footer>
    );
};

export default Footer;
