import { VscFolderLibrary } from 'react-icons/vsc';
import { FaAward } from 'react-icons/fa';
import { FiBook } from 'react-icons/fi';
import ME from '../../assets/me.jpg';
import React from 'react';
import './about.css';

function About() {
    return (
        <section id='about'>
            <h5>Get to Know</h5>
            <h2>About Me</h2>

            <div className='container about_container'>
                <div className='about_me'>
                    <div className='about_me-image'>
                        <img
                            src={ME}
                            alt='About Me'
                        />
                    </div>
                </div>
                <div className='about_content'>
                    <div className='about_cards'>
                        <article className='about_card'>
                            <FaAward className='about_icon' />
                            <h5>Experience</h5>
                            <small>1+ Years Working</small>
                        </article>
                        <article className='about_card'>
                            <FiBook className='about_icon' />
                            <h5>Major</h5>
                            <small>Master of Applied Computing</small>
                        </article>
                        <article className='about_card'>
                            <VscFolderLibrary className='about_icon' />
                            <h5>Projects</h5>
                            <small>10+ Completed</small>
                        </article>
                    </div>
                    <p>
                        Energetic and adaptable professional with a diverse
                        skill set encompassing project management, data
                        analysis, and client communication. Proven ability to
                        thrive in dynamic environments, leveraging strong
                        analytical and problem-solving skills to drive success.
                        Passionate about making meaningful contributions to
                        impactful projects and driving continuous improvement.
                    </p>
                    <a
                        href='#contact'
                        className='btn btn-primary'>
                        Let's Talk
                    </a>
                </div>
            </div>
        </section>
    );
}

export default About;
