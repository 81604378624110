import { BsPatchCheckFill } from 'react-icons/bs';
import React from 'react';
import './experience.css';

function Experience() {
    return (
        <section id='experience'>
            <h5>What Skill I Have</h5>
            <h2>My Experience</h2>

            <div className='container experience_container'>
                {/* ================ START OF FRONTEND ================ */}
                <div className='experience_frontend'>
                    <h3>FrontEnd Development</h3>
                    <div className='experience_content'>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>HTML</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>CSS</h4>
                                <small className='text-light'>
                                    Intermediate
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>JAVASCRIPT</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>BOOTSTARP</h4>
                                <small className='text-light'>
                                    Intermediate
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>Redux</h4>
                                <small className='text-light'>
                                    Intermediate
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>REACTJS</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* ================ END OF FRONTEND ================ */}

                {/* ================ START OF BACKEND ================ */}
                <div className='experience_backend'>
                    <h3>BackEnd Experience</h3>
                    <div className='experience_content'>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>NODE.JS</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>MONGODB</h4>
                                <small className='text-light'>
                                    Intermediate
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>ADONISJS</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>EXPRESS.JS</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>MYSQL</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                        <article className='experience_details'>
                            <BsPatchCheckFill className='experience_details-icon' />
                            <div>
                                <h4>PYTHON</h4>
                                <small className='text-light'>
                                    Experienced
                                </small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* ================ END OF BACKEND ================ */}
            </div>
        </section>
    );
}

export default Experience;
