import React from 'react';

const cta = () => {
    return (
        <div className='cta'>
            <a
                href='/Kishan_Resume_May_2024.pdf'
                download={'Kishan Resume May 2024'}
                target='_blank'
                className='btn'>
                Download CV
            </a>
            <a
                href='#contact'
                className='btn btn-primary'>
                Let's Talk
            </a>
        </div>
    );
};

export default cta;
